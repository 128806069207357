
































































































































import { PaginatedList } from "@/api/models/common/paginatedList";
import { StatusEnum } from "@/api/models/enums/statusPartenaireFASTT";
import { PartenaireFASTT } from "@/api/models/FASTT/partenaires/partenaire";
import {
    BButton,
    BCard,
    BCol,
    BDropdown,
    BFormSelect,
    BFormCheckbox,
    BDropdownItem,
    BFormInput,
    BPagination,
    BRow,
    BTable,
    BAlert,
    BBadge,
} from "bootstrap-vue";
import { Component, Vue, Watch } from "vue-property-decorator";
import CreatePartenaireFastt from "./Create.vue";
// import UpdateGroupe from "./EditGroupe.vue";

@Component({
    components: {
        BAlert,
        BCard,
        BRow,
        BCol,
        BButton,
        BBadge,
        BFormInput,
        BFormCheckbox,
        BTable,
        BPagination,
        BDropdown,
        BDropdownItem,
        BFormSelect,
        CreatePartenaireFastt,
    },
})
export default class ListPartenaires extends Vue {
  statusPartenairesFASTT = StatusEnum;

    perPage = 10;
    currentPage = 1;
    perPageOptions = [2, 5, 10, 25, 50, 100];
    timeout: any = null;
    searchQuery = "";
    isSortDirDesc = false;
    sortBy = "nom";
    isAddNewPartenaireSidebarActive = false;
    includePendingValidation = false;
    includeOldPartenaires = false;
    tableColumns = [
        { key: "nom", sortable: true },
        { key: "ville", sortable: true },
        { key: "telephone1", sortable: true },
        { key: "telephone2", sortable: true },
        { key: "statut", sortable: true }
    ];
    paginatedListPartenaires = new PaginatedList<PartenaireFASTT>();

    async created() {
      await this.loadListPartenaires({
        pageNumber: this.currentPage,
        pageSize: this.perPage,
        search: this.searchQuery,
        includeOldPartenaires: this.includeOldPartenaires,
        includePendingValidation: this.includePendingValidation
      });
    }

    get paginationFrom() {
        return (
            this.perPage * (this.currentPage - 1) +
            (this.paginatedListPartenaires.items.length ? 1 : 0)
        );
    }
    get paginationTo() {
        return (
            this.perPage * (this.currentPage - 1) +
            this.paginatedListPartenaires.items.length
        );
    }

    @Watch("currentPage")
    @Watch("perPage")
    @Watch("includeOldPartenaires")
    @Watch("includePendingValidation")
    async paginationChanged() {
      await this.loadListPartenaires({
        pageNumber: this.currentPage,
        pageSize: this.perPage,
        search: this.searchQuery,
        includeOldPartenaires: this.includeOldPartenaires,
        includePendingValidation: this.includePendingValidation
      });
    }

    @Watch('searchQuery')
    @Watch("sortBy")
    @Watch("isSortDirDesc")
    @Watch("includeOldPartenaires")
    @Watch("includePendingValidation")
    async searchPartenaire() {
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(async () => {
            await this.loadListPartenaires({
                pageNumber: this.currentPage,
                pageSize: this.perPage,
                orderBy: this.sortBy,
                orderByDescending: this.isSortDirDesc,
                search: this.searchQuery,
                includeOldPartenaires: this.includeOldPartenaires,
                includePendingValidation: this.includePendingValidation
            })
        }, 1200);
    }

    updateSort(params: any) {
        this.sortBy = params.sortBy;
        params = {
            pageNumber: this.currentPage,
            pageSize: this.perPage,
            orderBy: this.sortBy,
            orderByDescending: this.isSortDirDesc,
            includeOldPartenaires: this.includeOldPartenaires,
            includePendingValidation: this.includePendingValidation
        };
        this.isSortDirDesc = !this.isSortDirDesc;

        this.loadListPartenaires(params);
    }

    async loadListPartenaires(params: any) {
        if (!params || params?.reset) {
            this.paginatedListPartenaires = new PaginatedList<PartenaireFASTT>();
        }
        await this.$http.myressif.partenairesFASTT
            .paginatedList(
                params?.pageNumber ?? 1,
                params?.pageSize ?? 10,
                params?.orderBy,
                params?.orderByDescending,
                params?.search ?? "",
                params?.includePendingValidation,
                params?.includeOldPartenaires
            )
            .then((response: PaginatedList<PartenaireFASTT>) => {
                this.paginatedListPartenaires = response;
            });
    }

    onRowSelected(record: any) {
      this.$router.push({
        name: "details-partenaires-fastt",
        params: { id: record.id }
      });
    }
}
