export enum StatusEnum {
  EnAttente = "En attente validation",
  Valide = "Validé",
  Refuse = "Refusé",
  Retire = "Retiré"
}

export enum StatusEnumNumber {
  EnAttente,
  Valide,
  Refuse,
  Retire
}